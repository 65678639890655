import React from 'react';

import { Label } from '@/components/shared/form/label';
import {
  FieldMessage,
  FieldMessageProps,
} from '@/components/shared/form/message';
import { Flex } from '@/components/shared/layout/flex';
import type { CSS } from '@/stitches.config';
import { IThemeLCV } from '@/types/shared';
import { ITest } from '@/types/tracking';

interface FieldGroupProps {
  children?: React.ReactNode;
  css?: CSS;
  className?: string;
  theme?: IThemeLCV;
  htmlFor?: string;
  label?: string;
  secondaryLabel?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  message?: string;
  messageAlign?: FieldMessageProps['align'];
  messageTextColor?: FieldMessageProps['textColor'];
  showCount?: boolean;
  test?: ITest;
}

export const FieldGroup: React.FC<FieldGroupProps> = ({
  css,
  className,
  label,
  secondaryLabel,
  htmlFor,
  required,
  error = false,
  errorMessage,
  message,
  messageAlign,
  messageTextColor,
  children,
  theme,
  test,
  ...rest
}: FieldGroupProps) => {
  return (
    <Flex
      direction="column"
      rowGap="2"
      css={css}
      className={className}
      {...rest}
    >
      {!!label && (
        <Label
          htmlFor={htmlFor}
          label={label}
          secondaryLabel={secondaryLabel}
          required={required}
          theme={theme}
          weight="strong"
          test={{
            dataTest: test?.dataTest,
          }}
        />
      )}
      {children}
      {!!error && !!errorMessage && (
        <FieldMessage
          message={errorMessage}
          error={error}
          theme={theme}
          test={{
            dataTest: test?.dataTest,
          }}
        />
      )}
      {!errorMessage && !!message && (
        <FieldMessage
          message={message}
          align={messageAlign}
          textColor={messageTextColor}
          test={{
            dataTest: test?.dataTest,
          }}
        />
      )}
    </Flex>
  );
};

FieldGroup.displayName = 'FieldGroup';
