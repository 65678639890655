import { VariantProps } from '@stitches/react';
import React, { useMemo } from 'react';

import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { IThemeLCV } from '@/types/shared';
import { ITest } from '@/types/tracking';

export interface FieldMessageProps {
  /**
   * The message to display in the field message component.
   */
  message: string;
  /**
   * If true, the message will be displayed with an error color.
   */
  error?: boolean;
  /**
   * The text color of the message. If not provided, it will be determined by the error prop.
   */
  textColor?: VariantProps<typeof Text>['variant'];
  /**
   * The justification of the message. Defaults to 'left'.
   */
  align?: 'left' | 'center' | 'right';
  /**
   * The testing props for the component.
   */
  test?: ITest;
  theme?: IThemeLCV;
}

export const FieldMessage: React.FC<FieldMessageProps> = ({
  message,
  error = false,
  textColor,
  align = 'left',
  theme = 'light',
  test,
}: FieldMessageProps) => {
  const textAlign = useMemo(() => {
    switch (align) {
      case 'center':
        return 'center';
      case 'right':
        return 'end';
      default:
        return 'start';
    }
  }, [align]);

  return (
    <Box display="flex" gap="1" justifyContent={textAlign}>
      <Text
        theme={theme}
        size="text-caption"
        variant={
          textColor
            ? textColor
            : !error
            ? 'text-gray'
            : theme === 'dark'
            ? 'text-red-200'
            : 'text-red'
        }
        data-test={!!test?.dataTest && `message_${test?.dataTest}`}
      >
        {message}
      </Text>
    </Box>
  );
};
FieldMessage.displayName = 'FieldMessage';
