import React from 'react';

import { styled } from '@/stitches.config';

const StyledDivider = styled('hr', {
  all: 'unset',
  position: 'relative',
  display: 'block',
  us: 'none',
  pe: 'none',
  variants: {
    variant: {
      gray: {
        bc: '$gray400',
      },
      'gray-light': {
        bc: '$gray300',
      },
      'gray-dark': {
        bc: '$gray700',
      },
    },
    direction: {
      horizontal: {
        width: '100%',
        height: 1,
      },
      vertical: {
        width: 1,
        height: '100%',
      },
    },
    margin: {
      '2': {
        my: '$space-2',
      },
      '4': {
        my: '$space-4',
      },
      '6': {
        my: '$space-6',
      },
      '16': {
        my: '$space-7',
        '@md': {
          my: '$space-16',
        },
      },
    },
  },
  compoundVariants: [
    {
      direction: 'vertical',
      margin: '2',
      css: {
        mx: '$space-2',
      },
    },
    {
      direction: 'vertical',
      margin: '4',
      css: {
        mx: '$space-4',
      },
    },
    {
      direction: 'vertical',
      margin: '6',
      css: {
        mx: '$space-6',
      },
    },
    {
      direction: 'vertical',
      margin: '16',
      css: {
        mx: '$space-7',
        '@md': {
          mx: '$space-16',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'gray-light',
    direction: 'horizontal',
  },
});

interface DividerProps extends React.ComponentProps<typeof StyledDivider> {
  variant?: 'gray' | 'gray-light' | 'gray-dark';
  direction?: 'horizontal' | 'vertical';
  margin?: '2' | '4' | '6' | '16';
}

export const Divider: React.FC<DividerProps> = ({
  variant = 'gray-light',
  direction = 'horizontal',
  margin,
  ...rest
}: DividerProps) => {
  return (
    <StyledDivider
      variant={variant}
      direction={direction}
      margin={margin}
      {...rest}
    />
  );
};

Divider.displayName = 'Divider';
