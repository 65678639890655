import React, { ChangeEvent } from 'react';

import { Icon } from '@/components/shared/element/icons';
import { FieldGroup } from '@/components/shared/form/field-group';
import { styled, withDisplayName } from '@/stitches.config';
import { IThemeLCV } from '@/types/shared';
import { ITracking } from '@/types/tracking';

const IconWrapper = withDisplayName(
  styled('div', {
    position: 'absolute',
    right: 0,
    top: 0,
    pe: 'none',
    fontSize: '0',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 'var(--input-padding-inline)',
  }),
  'IconWrapper'
);

const SelectWrapper = withDisplayName(
  styled('div', {
    position: 'relative',
    height: 'var(--input-height)',
  }),
  'SelectWrapper'
);

const StyledSelect = styled('select', {
  ap: 'none',
  boxSizing: 'border-box',
  border: 'none',
  outline: 'none',
  margin: '$space-0',
  padding: '$space-0',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  paddingLeft: 'var(--input-padding-inline)',
  paddingRight: 8 + 24 + 16,
  br: 'var(--input-rounded)',
  bc: 'var(--input-background-color)',
  bs: 'inset 0px 0px 0px 1px var(--input-stoke-color)',
  transitionProperty: 'box-shadow, background-color, color',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  color: 'var(--input-color-placeholder)',
  fontFamily: '$regular',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'var(--input-height)',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:has(option:checked:not([value])), &:has(option:checked:not([value=""]))': {
    color: 'var(--input-color)',
    '& option': {
      color: 'var(--input-color)',
    },
  },
  '&::placeholder': {
    color: 'var(--input-color-placeholder)',
  },
  '&:-webkit-autofill': {
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'var(--input-color)',
  },
  '&:hover, &:focus': {
    bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-focus)',
  },
  '&:disabled': {
    opacity: '1',
    pe: 'none',
    cursor: 'not-allowed',
    us: 'none',
    color: 'var(--input-color-disabled)',
    bc: 'var(--input-background-color-disabled)',
    bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-disabled)',
    '&::placeholder': {
      color: 'var(--input-color-disabled)',
    },
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': 'var(--input-color-disabled)',
    },
  },
  '&::-ms-expand': {
    display: 'none',
  },
  '&.is-error': {
    color: 'var(--input-color-error)',
    bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-error)',
    '&::placeholder': {
      color: 'var(--input-color-error)',
    },
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': 'var(--input-color-error)',
    },
    '&:hover, &:focus': {
      bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-error)',
    },
  },
  variants: {
    theme: {
      light: {},
      dark: {
        color: 'var(--input-color-placeholder-dark)',
        bc: 'var(--colors-gray1100)',
        bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-dark)',
        '&:has(option:checked:not([value])), &:has(option:checked:not([value=""]))':
          {
            color: 'var(--input-color-dark)',
            '& option': {
              color: 'var(--input-color-dark)',
            },
          },
        '&:hover, &:focus': {
          bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-focus-dark)',
        },
        '&::placeholder': {
          color: 'var(--input-color-placeholder-dark)',
        },
        [`& ~ ${IconWrapper}`]: {
          '& i': {
            color: 'var(--input-color-placeholder-dark)',
          },
        },
        '&:disabled': {
          color: 'var(--input-color-disabled-dark)',
          bc: 'var(--input-background-color-disabled-dark)',
          bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-disabled-dark)',
          '&::placeholder': {
            color: 'var(--input-color-placeholder)',
          },
          [`& ~ ${IconWrapper}`]: {
            '& i': {
              color: 'var(--input-color-placeholder)',
            },
          },
        },
      },
    },
    error: {
      true: {
        color: 'var(--input-color-error)',
        bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-error)',
        '&::placeholder': {
          color: 'var(--input-color-error)',
        },
        [`& ~ ${IconWrapper}`]: {
          '& i': {
            color: 'var(--input-color-error)',
          },
        },
        '&:-webkit-autofill': {
          '-webkit-text-fill-color': 'var(--input-color-error)',
        },
        '&:hover, &:focus': {
          bs: 'inset 0px 0px 0px 1px var(--input-stoke-color-error)',
        },
      },
    },
  },
});

interface SelectProps extends React.ComponentProps<typeof StyledSelect> {
  children?: React.ReactNode;
  name: string;
  value?: string;
  autoComplete?: string;
  disabled?: boolean;
  tracking?: ITracking;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  secondaryLabel?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  message?: string;
  css?: any;
  className?: string;
  theme?: IThemeLCV;
}

export const Select: React.FC<SelectProps> = ({
  children,
  disabled,
  tracking,
  onChange,
  label,
  secondaryLabel,
  required = false,
  error = false,
  errorMessage,
  message,
  css,
  className,
  ...props
}: SelectProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(e);
  };

  return (
    <FieldGroup
      htmlFor={props?.name}
      label={label}
      secondaryLabel={secondaryLabel}
      required={required}
      error={error}
      errorMessage={errorMessage}
      message={message}
      css={css}
      className={className}
      test={{
        dataTest: props?.name,
      }}
      theme={props.theme}
    >
      <SelectWrapper>
        <StyledSelect
          data-track={tracking?.dataTrack}
          data-track-value={tracking?.dataTrackValue}
          data-track-text={tracking?.dataTrackText}
          data-track-url={tracking?.dataTrackUrl}
          data-test={`select_${props?.name}`}
          error={error}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        >
          {children}
        </StyledSelect>
        <IconWrapper>
          <Icon
            icon="expand-more"
            color={
              disabled
                ? 'icon-disabled'
                : error
                ? 'icon-red'
                : 'icon-gray-light'
            }
          />
        </IconWrapper>
      </SelectWrapper>
    </FieldGroup>
  );
};
Select.displayName = 'Select';
